<template>
  <div>
    <v-divider> </v-divider>
    <v-container>
      <div class="space">
        <v-row>
          <v-col cols="7">
            <h1 style="color:red">
             Wie zijn Wij
            </h1>

            <p>
              Over ons bedrijf Asco Software levert kassa software, Wordpress en Makelaardij CRM. Wij zijn
              gespecialiseerd in het ontwikkelen van software bedrijven. Wij leveren onze innovatieve
              software in België en Nederland. Ons bedrijf is opgericht in 2019
              waarbij wij gestart zijn met het ontwikkelen van modules voor
              kassasystemen. Wij zijn al snel overgestapt naar het bouwen van
              complete oplossingen. Waarom? Omdat hier een grote behoefte
              naar is bij MKB ondernemers. Wij vinden dat een software betaalbaar en innovatief moet zijn.
            </p>
            
          

            <v-btn color="red" class="ma-2 white--text" @click="goToContact()">
              Neem contact op
            </v-btn>
          </v-col>
          <v-col>
            <v-card class="mx-auto" max-width="400" elevation="9" shaped>
              <v-img
                class="white--text align-end"
                height="300px"
                src="@/assets/workspace.png"
              >
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({}),

  methods: {
    goToContact() {
      this.$router.push("/Contact");
    },
    goToAccount() {
      this.$router.push("/Inloggen");
    },
  },
};
</script>

<style scoped>
.space {
  margin-top: 15px;
  margin-bottom: 8px;
  border-style: none;
}
</style>
