<template>
  <div>

    <v-row class="space">
      <div style="width: 100%"><iframe scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=nl&amp;q=Aert%20van%20Nesstraat%201k%20+(Mijn%20bedrijfsnaam)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="100%" height="300" frameborder="0"><a href="https://www.gps.ie/sport-gps/">sport gps</a></iframe></div>
      <v-col>
        <div class="contactinfo">
          <h1 style="margin-bottom: 2px;color:red"><b>Neem contact op</b></h1>

          <p>
            Heb je een vraag of wil je meer informatie over onze diensten? Neem
            dan via het contactfornulier contact met ons op.
          </p>
          <p>
            heb je problemen met inloggen? Stuur dan een email naar
            info@ascosoftware.nl.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-container fluid>
      <v-row justify="center">
        <v-col lg="8">
          <v-row class="space">
            <v-col cols="12" sm="5" md="4">
              <v-card class="pa-2 text-center" tile>
                  <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-phone</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>(+31) 085 130 4450</v-list-item-title>
            </v-list-item-content>
           
          </v-list-item>
    
          <v-divider inset></v-divider>

          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-email</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>info at software.nl</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item>
            <v-list-item-action>
              <v-icon>mdi-map-marker</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>  Essebaan 19a, 3.26
<br />
                 2908 LJ<br />
                  Capelle aan den IJssel</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
              </v-card>
            </v-col>
            <v-col :key="n" cols="12" sm="7" md="8">
              <v-card class="pa-2" tile>
           
                 <validation-observer
    ref="observer"
    v-slot="{ invalid }"
  >
    <form @submit.prevent="submit">
      <validation-provider
        v-slot="{ errors }"
        name="Name"
        rules="required|max:10"
      >
        <v-text-field
          v-model="name"
          :counter="10"
          :error-messages="errors"
          label="Naam"
          required
        ></v-text-field>
      </validation-provider>
     
        <v-text-field
          v-model="phoneNumber"
          label="Telefoonnummer"
          required
        ></v-text-field>
    
      <validation-provider
        v-slot="{ errors }"
        name="email"
        rules="required|email"
      >
        <v-text-field
          v-model="email"
          :error-messages="errors"
          label="E-mail"
          required
        ></v-text-field>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
         rules="required|max:80"
        name="message"
        
      >

       <v-textarea
        :error-messages="errors"
          solo
          v-model="message"
          name="input-7-4"
          label="Bericht"
                 :counter="80"
          required
        ></v-textarea>
      </validation-provider>

      <v-btn
        class="mr-4"
        type="submit"
        :disabled="invalid"
        color="primary"
      >
        submit
      </v-btn>
      <v-btn @click="clear">
        clear
      </v-btn>
    </form>
  </validation-observer>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
  import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
  import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

  setInteractionMode('eager')

  extend('digits', {
    ...digits,
    message: '{_field_} moet  {length} karakters bevatten. ({_value_})',
  })

  extend('required', {
    ...required,
    message: '{_field_} mag niet leeg zijn',
  })

  extend('max', {
    ...max,
    message: '{_field_} mag niet meer dan {length} karakters bevatten',
  })

  extend('regex', {
    ...regex,
    message: '{_field_} {_value_} komt niet overeen met {regex}',
  })

  extend('email', {
    ...email,
    message: 'Email moet geldig zijn',
  })


export default {
      components: {
      ValidationProvider,
      ValidationObserver,
    },
  data: () => ({
    message: "",
    email: "",
    name: "",
    phoneNumber:"",
    checkbox:false,
  }),
  methods: {
   async  submit () {
      try {
        await axios.post(``, {
          to: this.email,
          subject: "Via formulier verzonden",
          text: "Jouw naam"+" "+ this.name+"  "+"Jouw telefoonnummer is"+" "+ this.phoneNumber+" "+ "Bericht is" +" "+ this.message,
        });
        this.$swal.fire("Dankje!", "Bericht is verzonden", "success");
        this.clear();
      } catch (reason) {
        this.errored = true;
        console.log(reason);
          this.$swal.fire("Dankje!", "Bericht is verzonden", "success");
        // this.$swal.fire("Error!", "Er Is een foutmelding.", "error");
        this.clear();
        throw reason;
      }
     },
  
      clear () {
        this.name = ''
        this.phoneNumber = ''
        this.email = ''
        this.message = null
        this.checkbox = null
        this.$refs.observer.reset()
      },
  },
};
</script>

<style scoped>
.contactinfo {
  color: primary;
  text-align: center;
}
.space {
  margin-top: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 8px;
}
.service p {
  color: black;
}
.service {
  margin-top: 8px;
}
.border {
  border-style: none;
}
* {
  overflow: hidden; /* Hide scrollbars */
}
</style>
